var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('div', {
    staticClass: "bg-white rounded-lg p-6"
  }, [_c('div', {
    staticClass: "grid lg:grid-cols-2 gap-6"
  }, [_c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "name"
    }
  }, [_vm._v("First name *")])])]), _c('p', [_c('input', {
    staticClass: "py-1 px-1 text-gray-900 outline-none block h-full w-full",
    attrs: {
      "id": "name",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    }
  })])]), _c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "lastname"
    }
  }, [_vm._v("Last name *")])])]), _c('p', [_c('input', {
    staticClass: "py-1 px-1 outline-none block h-full w-full",
    attrs: {
      "id": "lastname",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    }
  })])]), _c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "username"
    }
  }, [_vm._v("Username *")])])]), _c('p', [_c('input', {
    staticClass: "py-1 px-1 outline-none block h-full w-full",
    attrs: {
      "id": "username",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "text"
    }
  })])]), _c('div', {
    staticClass: "border focus-within:border-blue-500 focus-within:text-green-500 transition-all duration-500 relative rounded p-1"
  }, [_c('div', {
    staticClass: "-mt-4 absolute tracking-wider px-1 capitalize text-xs"
  }, [_c('p', [_c('label', {
    staticClass: "bg-white text-gray-700 px-1",
    attrs: {
      "for": "password"
    }
  }, [_vm._v("Password *")])])]), _c('p', [_c('input', {
    staticClass: "py-1 px-1 outline-none block h-full w-full",
    attrs: {
      "id": "password",
      "autocomplete": "false",
      "tabindex": "0",
      "type": "password"
    }
  })])])]), _c('div', {
    staticClass: "mt-6 pt-3 flex justify-center"
  }, [_c('button', {
    staticClass: "rounded text-gray-100 px-3 w-1/6 py-2 bg-green-600 hover:shadow-inner hover:bg-green-700 transition-all duration-300"
  }, [_vm._v(" Save ")])])])]);

}]

export { render, staticRenderFns }